import GATSBY_COMPILED_MDX from "/home/runner/work/personal-website/personal-website/content/posts/test.mdx";
import React from 'react';
import {updateClass} from '../utils/themes';
import useKeyboardShortcuts from '../hooks/use-keyboard-shortcuts';
import Navbar from '../components/navbar';
import {ContentContainer, Content, PreContent, PostContent} from '../components/content';
import PostTitle from '../components/post-title';
function Post(props) {
  useKeyboardShortcuts();
  var children = props.children;
  var _props$data$post = props.data.post, title = _props$data$post.title, date = _props$data$post.date;
  return React.createElement("div", null, React.createElement(Navbar, {
    title: "post: " + title
  }), React.createElement(ContentContainer, null, React.createElement(PreContent, null), React.createElement(Content, {
    className: "pt-20"
  }, React.createElement(PostTitle, {
    title: title,
    date: date,
    tags: [1, 2, 3, 4].map(function (i) {
      return "test" + i;
    })
  }), React.createElement("div", null, children)), React.createElement(PostContent, null)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head(props) {
  React.useEffect(updateClass, []);
  return React.createElement(React.Fragment, null, React.createElement("title", null, props.data.post.title + " | rodent.club"));
}
var pageQuery = "168176315";
